import { useRef, useCallback } from 'react';
import { citrusAdClick, metadataAdClick } from 'analytics/gtmServerFunctions';
import { useSelector } from 'react-redux';
import { getProductClickData } from 'redux/modules/entities/selectors/products';
import isEqual from 'lodash/isEqual';

import { handleProductListClick } from 'utils/gtm';

export const usePodClickTracking = ({
  conflictMessage,
  isFavourite,
  position,
  productId,
  searchType,
  wrapperId,
  boosted,
  sponsored,
}) => {
  const productData = useSelector(state => getProductClickData(state, productId), isEqual);
  const { sponsorshipId, metadata } = productData;

  const clickSentToCitrus = useRef(false);

  const reportClickToCitrus = useCallback(() => {
    if (sponsorshipId && !clickSentToCitrus.current) {
      citrusAdClick(sponsorshipId);
      clickSentToCitrus.current = true;
    }
  }, [sponsorshipId]);

  const reportClickToMonetate = useCallback(() => {
    if (metadata) metadataAdClick(metadata);
  }, [metadata]);

  const reportClickToDataLayer = useCallback(() => {
    handleProductListClick({
      conflictMessage,
      isFavourite,
      position,
      productData,
      searchType,
      wrapperId,
      boosted,
      sponsored,
      sponsorshipId,
    });
  }, [
    boosted,
    conflictMessage,
    isFavourite,
    position,
    productData,
    searchType,
    sponsored,
    sponsorshipId,
    wrapperId,
  ]);

  const reportAnalyticsClick = useCallback(() => {
    reportClickToDataLayer();
    reportClickToCitrus();
    reportClickToMonetate();
  }, [reportClickToCitrus, reportClickToDataLayer, reportClickToMonetate]);

  return {
    reportClickToDataLayer,
    reportClickToCitrus,
    reportClickToMonetate,
    reportAnalyticsClick,
  };
};
